.header {
  background-image: linear-gradient(transparent, white 99%), url("PNG/background.png");
  background-size: cover;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(20px + 2vmin);
    color: #000;
    text-align: center;

    h1 {
      font-size: 60px;
      font-weight: bold;
    }

    .link {
      color: #61dafb;
    }
  }
}
