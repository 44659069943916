$red: #D40613;
$primary: $red;

$body-font-size: 20pt;
$family-primary: 'Source Sans Pro', sans-serif;

@import "~bulma-checkradio/src/sass/index";
@import "~bulma/bulma";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/duotone";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";

html {
  height: 100%;
  box-sizing: border-box;
}


.mt-10 {
  margin-top: 100px !important;
}

@include from($desktop) {
  .my-10 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
}

.p-10 {
  padding: 100px !important;
}

.pt-10 {
  padding-top: 100px !important;
}

.py-10 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.py-20 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.pb-10 {
  padding-bottom: 100px !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF;
  color: #000;

  position: relative;
  min-height: 100%;
}

.columns {
  @include until($tablet) {
    &.is-reversed-touch {
      flex-direction: column-reverse;
      display: flex;
    }
  }
}

.Icons {
  font-weight: bolder;
}

.has-text-4x {
  font-size: 68px;
  margin-top: 8px;
  font-style: normal;
}

.WeAre {
  @include until($tablet) {
    .column {
      overflow: hidden;

      img {
        max-width: 150%;
        width: 150%;
        margin-left: -25%;
      }
    }
  }
}

.Funktion {
  .first-column {
    margin-top: 440px;
  }

  //margin-top: -330px;

  @include until($tablet) {
    margin-top: 100px;

    .first-column {
      margin-top: 100px;
    }
  }

  h2 {
    font-size: 32pt;
    font-weight: bold;
  }
}

.footer {
  background: none;
  padding: 0;

  .footer-start {
    background: url("SVG/Footer.svg") center top;
    background-size: cover;
    width: 100%;
    min-height: 800px;

    margin-bottom: 0;

    h1, h2 {
      font-size: 66pt;

      @include until($desktop) {
        line-height: 35pt;
      }

      line-height: 72pt;
      color: $red;
    }

    h2 {
      font-weight: bold;
      color: $red;
    }
  }

  footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    .navbar {
      @include until($navbar-breakpoint) {
        .navbar-menu {
          background-color: rgba(212, 28, 13, 0.5);
        }
      }

      .navbar-menu {
        background-color: rgba(212, 28, 13, 0.5);
      }

      .navbar-start {
        flex-grow: 1;
        justify-content: center;

        .navbar-menu {
          .navbar-item, .navbar-link {
            display: inline;

            &:hover, &:focus, &:focus-within, &.is-active {
              background-color: unset;
              color: #FFFFFF;
            }

            .navbar-link {
              font-size: 16px;
              color: $red;
            }
          }
        }
      }
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,600;0,700;1,200;1,400;1,600;1,700&display=swap');
